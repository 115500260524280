<template>
  <v-app>
    <v-alert small dense text type="info">Only peer-graded Writing assessments are available for Expert Grading.</v-alert>
    <div class="d-flex">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>

      <Filters />

      <div><es-btn @click="refresh" color="indigo"><fa icon="sync" /> Refresh</es-btn></div>
    </div>
    <v-card class='mb-6'>
      <v-data-table
        fixed-header
        height="62vh"
        sort-by='started_time'
        loading-text='Loading items ...'
        no-data-text='No items found.'
        :must-sort='true'
        :sort-desc='true'
        :search='search'
        :headers='headers'
        :items='items'
        :items-per-page='100'
        :loading='busy'
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        :footer-props="{ 'disable-items-per-page': true }"
      >
        <div v-show="busy" slot="progress" class="dot-windmill"></div>
        <template v-slot:[`item.started_time`]="{ item }">
          {{ item.formattedStartTime }}
        </template>
        <template v-slot:[`item.score`]="{ item }">
          {{ item.score || '-' }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <section class="d-flex flex-column ml-auto">
              <es-btn
                :title="`Go to Sitting`"
                class="es-btn"
                color="orange"
                @click="navigateTo(`/sittings/${item.sitting_id}`)"
              ><fa icon="school" /></es-btn>
              <es-btn
                :title="`Go to User`"
                class="es-btn"
                color="orange"
                @click="navigateTo(`/users?search=${item.user_id}`)"
              ><fa icon="users" /></es-btn>
            </section>
            <section class="d-flex flex-column">
              <es-btn
                :title="`Copy Assessment Instance ID to clipboard`"
                class="es-btn"
                color="primary"
                @click="copyToClipboard(item.assessment_instance_id)"
              ><fa icon="copy" /></es-btn>
              <es-btn
                :title="`Copy Assessment Type ID to clipboard`"
                class="es-btn"
                color="primary"
                @click="copyToClipboard(item.assessment_type_id)"
              ><fa icon="copy" /></es-btn>
            </section>

            <es-btn
              :title="loadSittingButtonTitle(item)"
              class="es-btn es-btn-copy white--text"
              color="secondary"
              @click="navigateToGradeSitting(item)"
              :disabled="loadSittingButtonDisabled(item)"
            ><fa icon="highlighter" /></es-btn>

          </div>
        </template>
      </v-data-table>
    </v-card>

    <GradeSitting />

  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Filters from '@/components/grading/Filters'
import GradeSitting from '@/components/grading/GradeSitting'
import * as clipboardy from 'clipboardy'

export default {
  data: () => ({
    search: '',
    dialog: false,
  }),
  components: {
    Filters,
    GradeSitting,
  },
  computed: {
    ...mapState('gradingList', [
      'headers',
      'items',
      'busy',
    ]),
  },
  methods: {
    ...mapActions('gradeSitting', [
      'loadSitting',
    ]),
    loadSittingButtonDisabled (item) {
      if (!item) return true
      const { grading_status, assessment_skills } = item
      if (grading_status !== 'Graded') return true
      return !assessment_skills.includes('Writing')
    },
    loadSittingButtonTitle (item) {
      if (!item) return ''
      const { grading_status, assessment_skills } = item
      if (grading_status !== 'Graded' || !assessment_skills.includes('Writing')) return '❌ Only peer-graded Writing assessments are available for expert grading.'
      return '✔️ Grade this Sitting'
    },

    // @note this.$router is not available in the vuex store, so navigation's handled here.
    navigateTo(path) {
      this.$router.push(path)
    },
    async copyToClipboard(string) {
      await clipboardy.write(string);
      this.$store.dispatch('snackbar/snack', {
        mode: 'success',
        message: `✅ Copied to clipboard: <strong class="px-4">${string}</strong>`
      })
    },
    navigateToGradeSitting({ sitting_id }) {
      this.$router.push({ name: "Grading", params: {}, query: {
        ...this.$route.query,
        sitting_id,
      }})
    },
    refresh() {
      this.$store.dispatch('gradingList/fetchItems')
    },
  },
  watch: {
    search(value) {
      if (this.$route.query.search === value) return

      const query = { ... this.$route.query }
      if (!value) delete query.search
      else query.search = value
      this.$router.push({ name: "Grading", params: this.$route.params, query })
    },
    $route: {
      handler() {
        this.search = this.$route.query.search
      },
      immediate: true
    }
  },
  mounted() {
    this.refresh()
  },
}
</script>

<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
  &:disabled{
    opacity: 0.2;
  }
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-data-footer__select {
    visibility: hidden;
  }
}
</style>
