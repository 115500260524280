<template>
  <v-dialog v-model="showGradingDialog" persistent max-width="900px">
    <v-card class="pa-6">
      <v-btn class="close-button" fab dark x-small depressed color="grey" @click="close" title="Cancel"><v-icon dark>mdi-close</v-icon></v-btn>

      <h1><fa icon="highlighter" /> Grade this sitting</h1>

      <section class="d-flex justify-space-between ml-3 mr-5 pb-5">
        <div><small class="blue-grey--text text--lighten-1">Sitting</small><br />{{ sitting.status }}</div>
        <div><small class="blue-grey--text text--lighten-1">Grading</small><br />{{ sitting.grading_status }}</div>
        <div><small class="blue-grey--text text--lighten-1">Assessment</small><br />{{ sitting.assessment_type_name }} </div>
        <div><small class="blue-grey--text text--lighten-1">Skill</small><br />{{ sitting.assessment_skills }}</div>
        <div><small class="blue-grey--text text--lighten-1">Score</small><br />{{ sitting.score }}</div>
        <div><small class="blue-grey--text text--lighten-1">CEFR</small><br />{{ sitting.cefr }}</div>
      </section>

      <center v-if="!sitting.hasResponses" >
        <p class="blue-grey--text text--lighten-1 my-16">
          <fa icon="info-circle" /> <strong>Sorry.</strong> There are no responses for this sitting.
        </p>
      </center>

      <template v-else-if="showOverview">
        <div class="col">
          <v-card elevation="2">

            <section class="d-flex mx-8 py-9">
              <v-card-text>
                <h2 class="mt-5 text--primary">Please confirm</h2>
                <div class="text--secondary">Here's the grades for this sitting. They'll be displayed on the sitting certificate.</div>
              </v-card-text>

              <div class="average-score-shield text-h4 text-center white--text pt-10">
                {{ averageScore }}
              </div>
            </section>

            <section class="mx-12 pb-8 d-flex justify-space-around">
              <div class="text-center mr-16">
                <h1><input type="number" min="0" max="600" class="text-h6 text-center" @input.capture="sanitiseNumberInput" v-model.number="sitting.languageGrade" /></h1>
                <label>Language</label>
              </div>
              <div class="text-center mr-16">
                <h1><input type="number" min="0" max="600" class="text-h6 text-center" @input.capture="sanitiseNumberInput" v-model.number="sitting.organisationGrade" /></h1>
                <label>Organization</label>
              </div>
              <div class="text-center">
                <h1><input type="number" min="0" max="600" class="text-h6 text-center" @input.capture="sanitiseNumberInput" v-model.number="sitting.communicationGrade" /></h1>
                <label>Communication</label>
              </div>
            </section>
          </v-card>
          <div class="d-flex justify-space-between mt-8">
            <button @click="() => showOverview = false" :disabled="busy" class="es-btn orange px-16 ml-auto">Back</button>
            <button @click="confirm" :disabled="busy" class="es-btn primary px-16">Confirm</button>
          </div>
        </div>
      </template>

      <template v-else>
        <v-carousel v-model.number="responseIndex" :show-arrows="false" vertical hide-delimiters>
          <v-carousel-item v-for="(response, i) in sitting.item_responses" :key="`response-${i}`">
            <div class="col d-flex flex-column" style="min-height:500px;">
              <v-card elevation="2" class="mb-8">
                <v-card-text>
                  <div class="d-flex justify-space-between">
                    <section>
                      <div class="text-overline">{{ response.template_type }}</div>
                      <div class="text--disabled mb-2">{{ item.rubric }} </div>
                      <div class="text-h6 text--primary">{{ item.speaking_text }}</div>
                      <div class="text-h6 text--primary">{{ response.question }}</div>
                    </section>
                    <img v-if="response.template_type === 'DESCRIBE_THE_IMAGE'" :src="imageSrc" height="160" />
                    <v-progress-circular
                      v-if="busy"
                      indeterminate
                      size="24"
                      color="amber"
                    ></v-progress-circular>
                  </div>

                  <v-divider class="my-3" />

                  <div class="text--primary">{{ response.response }}</div>
                </v-card-text>
              </v-card>

              <section class="mt-auto">
                <v-alert v-if="response.expertGradings" type="warning">
                  This response has been graded by experts {{ response.expertGradings.length}} times.
                </v-alert>
                <template v-if="!response.expertGradings || response.expertGradings.length < 3">
                  <section class="mb-2 d-flex justify-space-between">
                    <label>Language <input type="number" min="0" max="6" @focus="$event.target.select()" @input.capture="sanitiseNumberInput" v-model.number="response.languageGrade" /></label>
                    <label>Organization <input type="number" min="0" max="6" @focus="$event.target.select()" @input.capture="sanitiseNumberInput" v-model.number="response.organisationGrade" /></label>
                    <label>Communication <input type="number" min="0" max="6" @focus="$event.target.select()" @input.capture="sanitiseNumberInput" v-model.number="response.communicationGrade" /></label>
                  </section>
                  <label>
                    Comment
                    <input type="text" v-model="response.gradingComment" />
                  </label>
                </template>
              </section>

            </div>
          </v-carousel-item>
        </v-carousel>
        <div class="col d-flex justify-space-between mt-2">
          <div>Response {{ responseIndex + 1 }} of {{ sitting.item_responses.length }}</div>
          <button @click="() => responseIndex--" :disabled="busy || !responseIndex > 0" class="es-btn orange px-16 ml-auto">Previous</button>
          <button @click="() => responseIndex++" :disabled="busy || responseIndex + 1 === sitting.item_responses.length" class="es-btn teal px-16">Next</button>
          <button @click="submit" :disabled="busy || responseIndex + 1 !== sitting.item_responses.length || lastresponseWasGradedThreeOrMoreTimes" class="es-btn primary px-16 ml-auto">Submit</button>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: function() {
    return {
      // @note: Initialise responseIndex as false (not 0) to trigger the watcher when the grading dialog is opened.
      responseIndex: false,
      showOverview: false,
      averageScore: 0,
    }
  },
  computed: {
    ...mapState('gradeSitting',[
      'showGradingDialog',
      'sitting',
      'item',
      'busy',
    ]),
    imageSrc() {
      if (!this.item.assets?.images) return false
      return this.item.assets.images[0].file_location
    },
    lastresponseWasGradedThreeOrMoreTimes() {
      const { expertGradings } = this.sitting.item_responses?.at(-1)
      return expertGradings?.length >= 3
    },
  },
  methods: {
    ...mapActions('gradeSitting', [
      'loadItem',
      'saveGrading',
      'calculateAverageResponseGrades'
    ]),
    close() {
      // Note: This magically triggeres the showGradingDialog watcher below.
      this.$store.commit('gradeSitting/setShowGradingDialog', false)
    },
    submit() {
      this.calculateAverageResponseGrades()
      this.updateAverageScore()

      // If the sitting already has an overall score, continue to submit the grading scores.
      if (this.sitting.score >= 0) {
        this.saveGrading()
      }
      else {
        this.showOverview = true
      }
    },
    confirm() {
      this.saveGrading()
    },
    sanitiseNumberInput(event) {
      const { srcElement } = event
      const { value, min, max } = srcElement
      if (!value) srcElement.value = "0"
      if (Number(value) < Number(min)) srcElement.value = min
      if (Number(value) > Number(max)) srcElement.value = max
      this.updateAverageScore()
    },
    updateAverageScore() {
      // Give vue time to update state before calculating the average.
      requestAnimationFrame(() => {
        const { languageGrade, organisationGrade, communicationGrade } = this.sitting
        this.averageScore = Math.floor((Number(languageGrade) + Number(organisationGrade) + Number(communicationGrade)) / 3)
      })
    }
  },
  watch: {
    responseIndex(index) {
      if ( this.sitting.item_responses ) this.loadItem(this.sitting.item_responses[index].item_id)
    },
    showGradingDialog(showDialog) {
      const closing = !showDialog
      if (closing) {
        this.responseIndex = false
        this.showOverview = false
        this.$store.commit('gradeSitting/setSitting', {})
        const query = { ...this.$route.query }
        delete query.sitting_id
        this.$router.push({ name: "Grading", params: {}, query })
      }
    },
    $route: {
      handler() {
        if (this.$route.query.sitting_id) {
          this.$store.dispatch("gradeSitting/loadSitting", this.$route.query.sitting_id);
        }
        else {
          this.close()
        }
      },
      immediate: true
    }
  },
};
</script>

<style lang='scss' scoped>
h1 {
  text-align: center;
}

input, select {
  @include input;
  font-size: 12px;
}

label {
  font-size: 12px;
  cursor: pointer;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
}

.close-button {
  position: absolute;
  top: 25px;
  right: 35px;
}

.audio-player {
  border-radius: 10px;
  width: 100%;
}

.average-score-shield {
  width: 145px;
  background-image: url(../../assets/images/shield-writing.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

::v-deep {
  .v-carousel__item {
    overflow-y: auto;
  }
}

</style>
